import React, { Fragment } from 'react';
import useStyles from './styles';

function ConnectionsPlus() {
  const classes = useStyles();
  return (
    <Fragment>
      <span>Connections</span>
      <span className={classes.superscript}>+</span>
    </Fragment>
  );
}

export default ConnectionsPlus;
