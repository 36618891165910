import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IConnectionsPlusFeatureAlertIconProps extends ISvgIconProps {}

const ConnectionsPlusFeatureAlertIcon = (props: IConnectionsPlusFeatureAlertIconProps) => {
  const { className } = props;
  const svg = (
    <g>
      <g fill="none" fillRule="evenodd" transform="translate(-490 -169)">
        <g>
          <path d="M0 0H80V80H0z" transform="translate(490 169)" />
          <path fill="#A1A1A1" fillRule="nonzero" d="M34 59c1.235 1.8 3.53 3 6 3s4.765-1.2 6-3H34z" transform="translate(490 169)" />
          <path fill="#0C948B" fillRule="nonzero" d="M57.623 53.2s-3.247-4.9-3.247-11.2v-7c0-7.7-6.493-14-14.43-14-7.935 0-14.429 6.3-14.429 14v7c0 6.3-3.246 11.2-3.246 11.2-.361.525-.361 1.225 0 1.75.18.7.721 1.05 1.443 1.05H56.18c.722 0 1.263-.35 1.623-.875.361-.7.18-1.225-.18-1.925z" transform="translate(490 169)" />
        </g>
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 80 80" fontSize="inherit" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default ConnectionsPlusFeatureAlertIcon;