import React, { Fragment, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  FormControlLabel,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import CloseIcon from '@react/components/icons/closeIcon.component';
import PrimaryContainedButtonBase from '@react/components/button/base/primaryContainedButtonBase.component';
import ConnectionsPlusFeatureDataIcon from '@react/components/icons/connectionsPlusFeatureDataIcon.component';
import ConnectionsPlusFeatureMapIcon from '@react/components/icons/connectionsPlusFeatureMapIcon.component';
import ConnectionsPlusFeatureAlertIcon from '@react/components/icons/connectionsPlusFeatureAlertIcon.component';
import ConnectionsPlus from '@react/components/text/connectionsPlus/connectionsPlus.component';
import { CONNEX } from '@constants/common';
import CustomLink from '@react/components/customLink.component';
import {
  getLocalStorageConnexAcceptTermOfUse,
  saveLocalStorageConnexAcceptTermOfUse,
  saveLocalStorageTryFeatureNow,
  getLocalStorageTryFeatureNow,
} from '@react/utils/common.util';
import { SITE_URLS } from '@constants/routes';
import AtlasDataTrackable from '@react/components/atlas/atlas.component';
import CompactCheckbox from '@react/components/checkbox/compactCheckbox.component';
import ExclaimationMarkIconWithBorder from '@react/components/icons/exclaimationMarkIconWithBorder.component';
import AlertBox from '@react/components/alert/alertBox.component';
import messages from './messages';
import useStyles from './styles';

export interface IConnectionPlusFeatureDialogProps {
  open?: boolean;
  onClose?: () => void;
  onDoNotShowThisAgainChange?: (checked: boolean) => void;
  onTryFeatureNowClick?: () => void;
}

function ConnectionPlusFeatureDialog(props: IConnectionPlusFeatureDialogProps) {
  const {
    open,
    onClose,
    onDoNotShowThisAgainChange,
    onTryFeatureNowClick,
  } = props;

  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();

  const demoVideoUrl = CONNEX.URL_VIDEO_DEMO;

  const isNotMobileView = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [hasAcceptTerm, setHasAcceptTerm] = useState(
    getLocalStorageConnexAcceptTermOfUse(),
  );
  const [noShowAgain, setNoShowAgain] = useState(false);
  const [hasClickedTryFeatureNow, setHasClickedTryFeatureNow] = useState(
    getLocalStorageTryFeatureNow(),
  );
  const [storageHasAcceptTerm] = useState(
    getLocalStorageConnexAcceptTermOfUse(),
  );
  const [anchorElFlag, setAnchorElFlag] = useState(false);

  const handleOnTermOfUseChecked = (checked: boolean) => {
    saveLocalStorageConnexAcceptTermOfUse(checked);
  };

  const handleTryFeatureNow = (clicked: boolean) => {
    onTryFeatureNowClick();
    handleOnTermOfUseChecked(clicked);
    setHasClickedTryFeatureNow(clicked);
    saveLocalStorageTryFeatureNow(clicked);
  };

  const handleTooltip = () => {
    hasAcceptTerm ? setAnchorElFlag(true) : setAnchorElFlag(false);
  };

  const benefits = [
    {
      key: 'data',
      icon: (
        <ConnectionsPlusFeatureDataIcon className={classes.benefitsItemIcon} />
      ),
      label: (
        <Typography className={classes.benefitsItemText} variant="body1">
          <FormattedMessage {...messages.keyBenefitPointOne} />
        </Typography>
      ),
    },
    {
      key: 'map',
      icon: (
        <ConnectionsPlusFeatureMapIcon className={classes.benefitsItemIcon} />
      ),
      label: (
        <Typography className={classes.benefitsItemText} variant="body1">
          <FormattedMessage {...messages.keyBenefitPointTwo} />
        </Typography>
      ),
    },
    {
      key: 'alert',
      icon: (
        <ConnectionsPlusFeatureAlertIcon className={classes.benefitsItemIcon} />
      ),
      label: (
        <Typography className={classes.benefitsItemText} variant="body1">
          <FormattedMessage {...messages.keyBenefitPointThree} />
        </Typography>
      ),
    },
  ];

  /**
   * Handle the checkbox "Do Not Show This Again (DNSTA)"
   */
  const handleOnCheckBoxDNSTAChange = (checked: boolean) => {
    onDoNotShowThisAgainChange(checked);
    setNoShowAgain(checked);
  };

  const renderBenefits = () => {
    return benefits.map((benefit, index) => (
      <Grid key={`grid-item-feature-${benefit.key}`} item sm={4}>
        <Box
          className={classes.benefitsItemRoot}
          ml={index !== 0 && isNotMobileView ? 3 : 0}
        >
          {benefit.icon}
          {benefit.label}
        </Box>
      </Grid>
    ));
  };

  const renderBenefitsTitle = () => (
    <Grid item xs={12}>
      <div className={classes.benefitsTitleRoot}>
        <Typography variant="h5">
          <FormattedMessage {...messages.keyBenefits} />
        </Typography>
      </div>
    </Grid>
  );

  const renderButtonTryFeatureNow = () => (
    <Grid
      item
      xs={12}
      className={`${classes.textAlignCenter} ${classes.buttonTryFeatureNowRoot}`}
    >
      <PrimaryContainedButtonBase
        className={classes.tryFeatureButton}
        disabledClassName={classes.buttonTryFeatureNowDisabled}
        onClick={() => {
          !hasAcceptTerm && setAnchorElFlag(true);
          hasAcceptTerm && handleTryFeatureNow(true);
        }}
        {...AtlasDataTrackable({
          targetAttribute: 'connectsplus_try_features_btn',
        })}
      >
        <FormattedMessage {...messages.tryConnectionsPlus} />
      </PrimaryContainedButtonBase>
    </Grid>
  );

  const renderOptionTermOfUse = () => {
    const renderTermOfUse = (
      <Fragment>
        <Typography variant="body2">
          {intl.formatMessage(messages.acknowledge)}
          <CustomLink to={SITE_URLS.CONNECTION_PLUS_TERMS} target="_blank">
            {intl.formatMessage(messages.termOfUse)}
          </CustomLink>
          {intl.formatMessage(messages.provideBy)}
        </Typography>
      </Fragment>
    );

    const renderAlert = () => {
      return (
        <>
          {anchorElFlag && (
            <AlertBox
              icon={<ExclaimationMarkIconWithBorder />}
              className={classes.warnIcons}
            >
              <Typography className={classes.alertContent}>
                {intl.formatMessage(messages.pleaseTickTheBoxToProceed)}
              </Typography>
            </AlertBox>
          )}
        </>
      );
    };

    return hasAcceptTerm && storageHasAcceptTerm ? null : (
      <>
        <Grid item xs={12} className={classes.textAlignCenter}>
          {renderAlert()}
          <FormControlLabel
            className={classes.fromLabelBox}
            checked={hasAcceptTerm}
            control={
              <CompactCheckbox
                labelId="termOfUse"
                checked={hasAcceptTerm}
                className={classes.checkBox}
                onChange={(event) => {
                  const checked = event.currentTarget.checked;
                  setHasAcceptTerm(checked);
                  handleTooltip();
                }}
                {...AtlasDataTrackable({
                  targetAttribute: 'connectsplus_terms_use_chkbox',
                })}
              />
            }
            label={renderTermOfUse}
            classes={{
              label: classes.termOfUseRoot,
            }}
            {...AtlasDataTrackable({
              targetAttribute: 'connectsplus_terms_use_chkbox',
            })}
          />
        </Grid>
      </>
    );
  };

  const renderDialogAction = () =>
    hasClickedTryFeatureNow ? (
      <DialogActions classes={{ root: classes.dialogAction }}>
        <FormControlLabel
          control={
            <CompactCheckbox
              labelId="Do Not Show This Again"
              checked={noShowAgain}
              onChange={(event) => {
                const noShowAgainChecked = event.currentTarget.checked;
                handleOnCheckBoxDNSTAChange(noShowAgainChecked);
              }}
              {...AtlasDataTrackable({
                targetAttribute: 'connectsplus_do_not_show_chkbox',
              })}
            />
          }
          label={intl.formatMessage(messages.doNotShowThisAgain)}
        />
      </DialogActions>
    ) : null;

  const renderDialogContent = () => (
    <DialogContent dividers={true} classes={{ root: classes.dialogContent }}>
      <Grid container>
        {renderBenefitsTitle()}
        {renderBenefits()}
        {renderTrialMessage()}
        {renderOptionTermOfUse()}
        {renderButtonTryFeatureNow()}
        {renderQuickDemoTitle()}
        {renderQuickDemoVideo()}
      </Grid>
    </DialogContent>
  );

  const renderDialogTitle = () => (
    <DialogTitle id="scroll-dialog-title" className={classes.dialogTitleRoot}>
      <Typography className={classes.titleRoot} component="span" variant="h5">
        {renderTextTitle()}
      </Typography>
      <IconButton
        className={classes.closeButton}
        onClick={(_) => {
          onClose();
        }}
        {...AtlasDataTrackable({
          targetAttribute: 'connectsplus_close_modal_btn',
        })}
        size="large"
      >
        <CloseIcon color={theme.palette.secondary.main} />
      </IconButton>
    </DialogTitle>
  );

  const renderQuickDemoTitle = () => (
    <Grid item xs={12}>
      <Box className={classes.quickDemoTitleRoot}>
        <Typography variant="h5">
          <FormattedMessage {...messages.aQuickDemo} />
        </Typography>
      </Box>
    </Grid>
  );

  const renderQuickDemoVideo = () => (
    <Grid item xs={12}>
      <Box className={classes.quickDemoVideoRoot}>
        {demoVideoUrl && (
          <iframe
            width="100%"
            height="100%"
            src={demoVideoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </Box>
    </Grid>
  );

  const renderTrialMessage = () => (
    <Grid item xs={12}>
      <div className={classes.trialMessageRoot}>
        <Typography variant="h6">
          <FormattedMessage {...messages.trialMessage} />
        </Typography>
        <ul className={classes.trialUl}>
          <li>
            <FormattedMessage {...messages.capitalMarketsDatasetFor10} />
          </li>
          <li>
            {intl.formatMessage(messages.countryCorporateRegistryDatasetFor10, {
              country: 'Malaysia',
            })}
          </li>
          <li>
            {intl.formatMessage(messages.countryCorporateRegistryDatasetFor10, {
              country: 'Vietnam',
            })}
          </li>
        </ul>
      </div>
    </Grid>
  );

  const renderTextTitle = () => (
    <Fragment>
      <Typography variant="h5">
        <FormattedMessage {...messages.whyTryOne} />
      </Typography>
      <Typography variant="h5">
        <ConnectionsPlus />
      </Typography>
      <Typography variant="h5">
        <FormattedMessage {...messages.whyTryTwo} />
      </Typography>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onBackdropClick={() => {
        onClose();
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        paper: classes.paper,
      }}
    >
      {renderDialogTitle()}
      {renderDialogContent()}
      {renderDialogAction()}
    </Dialog>
  );
}

export default ConnectionPlusFeatureDialog;
