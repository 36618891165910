import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface IExclaimationMarkIconWithBorderProps extends ISvgIconProps {
  className?: string,
}

const ExclaimationMarkIconWithBorder = (props: IExclaimationMarkIconWithBorderProps) => {
  const { className } = props;
  const svg = (
    <svg width="16" height="16" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8369 21V3H4.08691V21H22.8369ZM5.79146 4.5V19.5H21.1324V4.5H5.79146ZM14.3142 7.5H12.6096V14.25H14.3142V7.5ZM13.4619 17.25C13.9326 17.25 14.3142 16.9142 14.3142 16.5C14.3142 16.0858 13.9326 15.75 13.4619 15.75C12.9912 15.75 12.6096 16.0858 12.6096 16.5C12.6096 16.9142 12.9912 17.25 13.4619 17.25Z"
        fill="#111111"
      />
      <mask id="mask0_548_66" maskUnits="userSpaceOnUse" x="4" y="3" width="19" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8369 21V3H4.08691V21H22.8369ZM5.79146 4.5V19.5H21.1324V4.5H5.79146ZM14.3142 7.5H12.6096V14.25H14.3142V7.5ZM13.4619 17.25C13.9326 17.25 14.3142 16.9142 14.3142 16.5C14.3142 16.0858 13.9326 15.75 13.4619 15.75C12.9912 15.75 12.6096 16.0858 12.6096 16.5C12.6096 16.9142 12.9912 17.25 13.4619 17.25Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_548_66)">
        <rect x="0.961914" width="25" height="24" fill="#404040" />
      </g>
    </svg>
  );

  return (
    <SvgIcon viewBox="0 0 16 16" className={className}>
      {svg}
    </SvgIcon>
  );
};

ExclaimationMarkIconWithBorder.defaultProps = {} as Partial<IExclaimationMarkIconWithBorderProps>;

export default ExclaimationMarkIconWithBorder;