import { BORDER } from '@constants/style';
import { Theme, alpha } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IStyleProps {
  size: number,
  disabled: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IStyleProps) => ({
      borderRadius: 2,
      padding: 0,
      color: theme.palette.secondary.main,
      border: `1px solid ${BORDER.COLOR}`,
      width: theme.spacing(props.size),
      height: theme.spacing(props.size),
    }),
    icon: (props: IStyleProps) => ({
      width: theme.spacing(props.size),
      height: theme.spacing(props.size),
    }),
    
    checkButton: {
      marginRight: 3,
      backgroundColor: 'white',
      '& > MuiIconButton-label:hover':{
        backgroundColor: '#D4ECEA !important',
      },
      '& > .MuiSvgIcon-root': {
        fontSize: '1rem !important'
      },
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
      '&.active': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
      },
    },
    activeButton: {
      color: 'white !important',
      backgroundColor: theme.palette.primary.main,
    },
    showBorder: {
      border: 'none!important',
      color: 'white !important',
      backgroundColor: theme.palette.primary.main,
    },
    compactCheckboxRoot: {
      width: '16px',
      height: '16px',
      margin: '14px 6px 14px 0',
      position: 'relative',
      padding: 0,
    },
    compactCheckbox: (props: IStyleProps) => ({
      width: '16px',
      height: '16px',
      border: '1px solid #a7aaae',
      appearance: 'none',
      '-webkit-appearance': 'none',
      borderRadius: 0,
      margin: 'auto',
      backgroundColor: 'white',
      opacity: props.disabled ? '0.25' : 'unset',
      '&:hover': !props.disabled ? {
        backgroundColor: 'rgba(13,118,128,.1)',
        color: '#0d7680',
        borderColor: '#0d7680',
        cursor: 'pointer',
      } : {},
      '&:focus': {
        outlineOffset:'0px',
        outline: '0px',
      },
      '&:checked': {
        backgroundColor: '#007b78',
        display: 'none',
      },
    }),
    compactCheckboxIcon: {
      width: '16px',
      height: '16px',
      border: '1px solid #a7aaae',
      backgroundColor: '#007b78',
      '& svg': {
        width: '12px',
        height: '12px',
        position: 'absolute',
        left: '2px',
        top: '2px',
      },
    },
  }),
);

export default useStyles;