import React, { FC, ReactNode } from 'react';
import { Typography, Box } from '@mui/material';
import classnames from 'classnames';
import useStyles from './styles';

export interface IAlertBoxProps {
  icon: ReactNode,
  className?: string,
}

const AlertBox:FC<IAlertBoxProps> = (props) => {
  const {
    icon,
    className,
    children,
  } = props;
  const classes = useStyles();


  return (
    <Box
      className={classnames(classes.root, className)}
    >
      <Typography
        className={classes.icons}
      >
        {icon}
      </Typography>
      <Typography
        className={classes.message}
      >
        {children}
      </Typography>
    </Box>
  );

};

export default AlertBox;