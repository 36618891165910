import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'rgb(255, 231, 236)',
      display: 'flex',
      padding: '6px 1rem',
      fontSize: '0.875rem',
      lineHeight:'1.43',
    },
    icons: {
      display: 'flex',
      padding: '7px 0',
      marginRight: '0.68rem',
    },
    message: {
      padding: '8px 0',
    },
  }),
);

export default useStyles;