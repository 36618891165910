import React, { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { isLoadingUserSelector } from '@redux/selectors/oidc.selector';
import { subscriptionFeaturesObjectSelector } from '@redux/selectors/account.selector';

interface ISubscriptionFeatureOwnProps {
  /**
   * Subscription string to check against
   * current login user featureList
   */
  subscription: string,
  /**
   * A React node to display if subscription is valid
   */
  access?: ReactNode,
  /**
   * A React node to display if subscription is not valid
   */
  deny?: ReactNode,
  loader?: ReactNode,
  isMobile?: boolean,
}

type ISubscriptionPermissionScope = 'FULL' | 'LOCKED' | 'NONE' | string;

interface ISubscriptionFeatureStateProps {
  subscriptionFeatures?: {
    [key: string]: ISubscriptionPermissionScope
  };
  isLoadingUser?: boolean;
}

interface ISubscriptionFeatureDispatchProps {
}

export interface ISubscriptionFeatureProps extends
  ISubscriptionFeatureOwnProps,
  ISubscriptionFeatureStateProps,
  ISubscriptionFeatureDispatchProps {}

const SubscriptionFeature = (props: ISubscriptionFeatureProps) => {
  const {
    subscription,
    subscriptionFeatures,
    access,
    deny,
    loader,
    isLoadingUser,
    isMobile,
  } = props;

  // for more subscription type in the future, should handle more case here.
  const isAccessible = (isEmpty(subscriptionFeatures) || subscriptionFeatures[subscription] === 'LOCKED') ?
    false : !!subscriptionFeatures[subscription];

  const renderAccessComponent = (access || <></>);

  const renderDenyComponent = (deny || <></>);
  
  if (isLoadingUser || isMobile) {
    return <>{loader || null}</>;
  }

  return (
    <Fragment>
      {
        isAccessible ?
          renderAccessComponent :
          renderDenyComponent
      }
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return ({
    isLoadingUser: isLoadingUserSelector(state),
    subscriptionFeatures: subscriptionFeaturesObjectSelector(state),
    subcriptionFeaturePermissionModel: state.getIn(['Account', 'accountInfo', 'subcriptionFeaturePermissionModel']),
  });
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
});

const withConnect = connect<
ISubscriptionFeatureStateProps, 
ISubscriptionFeatureDispatchProps, 
ISubscriptionFeatureOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(SubscriptionFeature);
