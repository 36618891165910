import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FONT } from '@constants/style';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertTermOfUseRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        fontSize: '18px',
      },
      '& > p': {
        color: '#d4001a',
        lineHeight: '16px',
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
    },
    buttonTryFeatureNowDisabled: {
      backgroundColor: '#adefea !important',
      borderColor: '#adefea !important',
      color: '#a1a1a1 !important',
    },
    buttonTryFeatureNowRoot: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    dialogTitleRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 24px',
    },
    titleRoot: {
      display: 'flex',
      fontSize: theme.typography.h5.fontSize,
      justifyContent: 'center',
      '& h5': {
        color: theme.palette.primary.main,
        fontWeight: FONT.WEIGHT.SEMIBOLD,
        fontSize: '24px',
        lineHeight: '28px',
      },
      '& h5:first-child': {
        marginRight: theme.spacing(0.5),
      },
    },
    closeButton: {
      fontSize: '12px',
      paddingRight: 0,
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    benefitsTitleRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),
      '& > h5': {
        lineHeight: 1.17,
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
    },
    benefitsItemRoot: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > p': {
        maxWidth: '100%',
      },
    },
    benefitsItemIcon: {
      fontSize: '80px',
    },
    benefitsItemText: {
      lineHeight: 1.25,
      marginTop: theme.spacing(2),
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    customTourButtonRoot: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(7),
    },
    trialMessageRoot: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      '& h6, li': {
        fontSize: '1.25rem',
        lineHeight: '24px',
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
    },
    quickDemoTitleRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5),
      '& > h5': {
        lineHeight: 1.17,
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
    },
    quickDemoVideoRoot: {
      background: '#d8d8d8',
      width: '100%',
      height: '480px',
    },
    stillUnsureTitleRoot: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(5),
    },
    termOfUseRoot: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: '0.875rem',
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      lineHeight: '1.14',
      textAlign: 'left',
      '& > p': {
        fontWeight: FONT.WEIGHT.MEDIUM,
        '& > a': {
          marginRight: theme.spacing(0.5),
          color: FONT.COLOR.PRIMARY.MAIN,
          fontWeight: FONT.WEIGHT.BOLD,
        },
      },
    },
    paper: {
      width: '100%',
      maxWidth: '960px',
      height: '720px',
      maxHeight: '100%',
      borderRadius: 0,
      [theme.breakpoints.down(1441)]: {
        maxWidth: '720px',
      },
      [theme.breakpoints.down(721)]: {
        maxWidth: '100%',
      },
    },
    tooltipPopper: {
      background: '#404040',
      top: '1rem',
      [theme.breakpoints.down(1441)]: {
        top: '0.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        top: '3rem',
      },
    },
    tooltipContent: {
      padding: '10px 15px',
      color: '#FFFFFF',
      fontSize: '1rem',
    },
    tooltipArrow: {
      left:'0! important',
      color: '#404040',
    },
    dialogContent: {
      padding: '16px 54px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 25px',
      },
    },
    tryFeatureButton: {
      minWidth: '180px',
      height: '40px',
      margin: 'auto',
    },
    termOfUseCheckBox: {
      '& input': {
        border: '1px solid #a7aaae',
        borderRadius: 0,
      },
    },
    dialogAction: {
      padding: 0,
    },
    checkBox: {
      [theme.breakpoints.down(1441)]: {
        margin: '0px 6px 14px 0',
      },
    },
    fromLabelBox: {
      marginLeft: '0',
    },
    warnIcons: {
      marginBottom: '12px',
    },
    alertContent: {
      textAlign: 'left',
    },
    trialUl: {
      margin: 0,
      paddingLeft: '28px',
    },
  }),
);

export default useStyles;