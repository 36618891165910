import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { Box, Button } from '@mui/material';
import CheckIcon from '@react/components/icons/checkIcon.component';
import { EMPTY_STRING } from '@constants/common';
import useStyles from './styles';
import AtlasDataTrackable, { IAtlasDataTrackable } from '../atlas/atlas.component';

export interface CompactCheckboxProps {
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectAll?: boolean;
  labelId?: string;
  className?: string;
  disabled?: boolean;
  tableAtlasTrackable?: IAtlasDataTrackable;
}
 
const CompactCheckbox: React.FC<CompactCheckboxProps> = (props) => {
  const { checked, onChange, selectAll, labelId, className, disabled, tableAtlasTrackable } = props;
  const classes = useStyles({ size: 0, disabled });
  const id = `compactCheckbox-${selectAll ? 'select-all' : EMPTY_STRING}${labelId || EMPTY_STRING}`;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        style={{
          display: 'none',
        }}
        ref={buttonRef}
        {...AtlasDataTrackable(tableAtlasTrackable || {})}
      />
      <Box className={classnames(classes.compactCheckboxRoot, className)}>
        <label htmlFor={id}>
          <input 
            id={id} 
            type="checkbox" 
            className={classes.compactCheckbox} 
            checked={checked} 
            onChange={(event) => {
              onChange(event);
              setTimeout(() => buttonRef.current.click());
            }} 
            disabled={disabled}
          />
          {
          checked ?
            <Box 
              className={classes.compactCheckboxIcon}
            >
              <CheckIcon 
                color="white" 
              />
            </Box> : null
        }
        </label>
      </Box>
    </>
  
  );
};
 
export default CompactCheckbox;