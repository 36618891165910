import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IConnectionsPlusFeatureDataIconProps extends ISvgIconProps {}

const ConnectionsPlusFeatureDataIcon = (props: IConnectionsPlusFeatureDataIconProps) => {
  const { className } = props;
  const svg = (
    <g>
      <g fill="none" fillRule="evenodd" transform="translate(-83 -169)">
        <g>
          <g>
            <path fill="#BABABA" d="M4 4H52V65H4z" transform="translate(83 169) translate(14 7)" />
            <g transform="translate(83 169) translate(14 7)">
              <path fill="#000" d="M0 0H48V61H0z" />
              <path fill="#FFF" d="M0 0H48V61H0z" />
              <path fill="#707070" fillRule="nonzero" d="M42 30L42 32 6 32 6 30z" />
              <path fill="#404040" fillRule="nonzero" d="M42 44L42 46 6 46 6 44z" />
              <path fill="#707070" fillRule="nonzero" d="M42 37L42 39 27 39 27 37zM42 24L42 26 28 26 28 24zM42 18L42 20 32 20 32 18z" />
              <path fill="#404040" fillRule="nonzero" d="M24 37L24 39 6 39 6 37zM42 51L42 53 17 53 17 51z" />
              <circle cx="6.5" cy="52.5" r="1.5" fill="#878787" />
              <circle cx="11.5" cy="52.5" r="1.5" fill="#878787" />
              <g transform="translate(5 6)">
                <circle cx="12" cy="10.5" r="10.5" fill="#0C948B" />
                <path stroke="#FFF" strokeLinecap="round" strokeWidth="1.5" d="M12.5 23L12.5 10M.5 6.5L12.5 10" />
              </g>
              <path stroke="#979797" strokeLinecap="square" d="M29.5 15.5L29.5 15.5" />
            </g>
          </g>
          <path d="M0 0H80V80H0z" transform="translate(83 169)" />
        </g>
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 80 80" fontSize="inherit" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default ConnectionsPlusFeatureDataIcon;