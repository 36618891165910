import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.dialog.connectionPlusFeature';

export default defineMessages({
  aQuickDemo: {
    id: `${scope}.aQuickDemo`,
    defaultMessage: 'A quick demo',
  },
  doNotShowThisAgain: {
    id: `${scope}.doNotShowThisAgain`,
    defaultMessage: 'Do not show this again',
  },
  keyBenefits: {
    id: `${scope}.keyBenefits`,
    defaultMessage: 'Key benefits',
  },
  keyBenefitPointOne: {
    id: `${scope}.keyBenefitPointOne`,
    defaultMessage: 'Comprehensive, reliable public and private company data across Singapore, Malaysia and Vietnam',
  },
  keyBenefitPointTwo: {
    id: `${scope}.keyBenefitPointTwo`,
    defaultMessage: 'Our proprietary map features draw direct and indirect connections between entities and persons',
  },
  keyBenefitPointThree: {
    id: `${scope}.keyBenefitPointThree`,
    defaultMessage: 'Alerts that update you on shareholding and directorship changes of target companies',
  },
  pleaseTickTheBoxToProceed: {
    id: `${scope}.pleaseTickTheBoxBelowToProceed`,
    defaultMessage: 'Please tick the box below to proceed.',
  },
  acknowledge: {
    id: `${scope}.acknowledge`,
    defaultMessage: 'I acknowledge Connections Plus is a service provided by DC Frontiers (not Nikkei Inc.) and agree to the ',
  },
  provideBy: {
    id: `${scope}.provideBy`,
    defaultMessage: 'provided by DC Frontiers.',
  },
  termOfUse: {
    id: `${scope}.termOfUse`,
    defaultMessage: 'Terms of Use',
  },
  trialMessage: {
    id: `${scope}.trialMessage`,
    defaultMessage: 'This trial allows you to retrieve:',
  },
  capitalMarketsDatasetFor10: {
    id: `${scope}.capitalMarketsDatasetFor10`,
    defaultMessage: '10 maps and/or reports from the Capital Markets dataset', 
  },
  countryCorporateRegistryDatasetFor10: {
    id: `${scope}.countryCorporateRegistryDatasetFor10`,
    defaultMessage: '10 maps and/or reports from the {country} Corporate Registry dataset', 
  },
  tryConnectionsPlus: {
    id: `${scope}.tryConnectionsPlus`,
    defaultMessage: 'Try Connections PLUS',
  },
  whyTryOne: {
    id: `${scope}.whyTryOne`,
    defaultMessage: 'Why try ',
  },
  whyTryTwo: {
    id: `${scope}.whyTryTwo`,
    defaultMessage: '?',
  },
});