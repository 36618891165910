import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IConnectionsPlusFeatureMapIconProps extends ISvgIconProps {}

const ConnectionsPlusFeatureMapIcon = (props: IConnectionsPlusFeatureMapIconProps) => {
  const { className } = props;
  const svg = (
    <g>
      <g fill="none" fillRule="evenodd" transform="translate(-289 -169)">
        <g>
          <g transform="translate(289 169) translate(2 11)">
            <path fill="#979797" fillRule="nonzero" d="M14.101 12.198c.149-.196.413-.252.624-.144l.077.047 16.5 12.5c.22.167.263.48.097.7-.149.197-.413.253-.624.145l-.077-.047-16.5-12.5c-.22-.167-.263-.48-.097-.7zM63.335 20.028c.26-.091.546.046.637.307.081.232-.018.483-.225.6l-.082.037-20 7c-.26.091-.546-.046-.637-.307-.081-.232.018-.483.225-.6l.082-.037 20-7zM39.276 35.053c.22-.11.482-.043.624.147l.047.076 4 8c.124.247.024.548-.223.671-.22.11-.482.043-.624-.147l-.047-.076-4-8c-.124-.247-.024-.548.223-.671z" />
            <circle cx="7" cy="7" r="7" fill="#0C948B" />
            <circle cx="36.5" cy="29.5" r="4.5" fill="#404040" />
            <ellipse cx="68.896" cy="18.132" fill="#BABABA" rx="4.196" ry="4.214" transform="rotate(-58 68.896 18.132)" />
            <circle cx="46.973" cy="49.853" r="5.5" fill="#0EB0A5" transform="rotate(-27 46.973 49.853)" />
          </g>
          <path d="M0 0H80V80H0z" transform="translate(289 169)" />
        </g>
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 80 80" fontSize="inherit" className={className}>
      {svg}
    </SvgIcon>
  );
};

export default ConnectionsPlusFeatureMapIcon;